import React from "react";
import Layout from "../components/Layout/Layout";
import Typography from "../components/Typography/Typography";
import SEO from "../components/Seo";
import section1img from "../images/hiw_section1.svg";
import section2img from "../images/hiw_section2.svg";
import section3img from "../images/hiw_section3.svg";
import * as classes from "../styles/hiw.module.scss";
import HowItWorkSection from "../components/HowItWorks/HowItWorkSection";
import RecruiterFlow from "../components/HowItWorks/RecruiterFlow";
import EmployerFlow from "../components/HowItWorks/EmployerFlow";

export default function hiw() {
  return (
    <Layout>
      <SEO title="How It Works" />
      <div className={classes.root}>
        <div>
          {" "}
          <Typography variant="h1" align="center">
            How it works
          </Typography>
        </div>
        <div className={classes.header}>
          <Typography variant="p">
            At Experts Circle, we optimize job placements with a dual-path
            approach. Hiring managers post job openings for our experts to suggest top candidates. Experts also proactively
            nominate candidates for both advertised and unadvertised roles,
            tapping into their extensive networks to cover all opportunities.
          </Typography>
        </div>
        <div></div>
        <section className={classes.section}>
          <div className={classes.secitonMainContent}>
            {/* <img src={section1img} alt="Experts" /> */}
            <div className={classes.sectionInfo}>
              <Typography variant="h2" align="left" borderBottom>
                Experts
              </Typography>

              <Typography variant="p">
              At Experts Circle, our <b>Experts</b> are more than just talent scouts; they are trusted professionals with deep industry knowledge and a proven ability 
              to identify top talent. These specialists leverage their expertise to revolutionise the hiring process. As integral members of Experts Circle, 
              they connect leading employers with top-tier talent, utilising their insights to match individuals with roles perfectly aligned to their skills.
            </Typography>


              <RecruiterFlow />

              <Typography variant="p">
                <p>
                  <h4>Your Journey as an Expert at Experts Circle</h4>
                  <ul>
                    <li>
                      <b>Registration and Verification:</b> Begin your journey
                      at Experts Circle by signing up as an Expert.
                      Our comprehensive verification process ensures that only
                      true industry connoisseurs like you can recommend
                      candidates, guaranteeing trust and quality in every
                      referral.
                    </li>

                    <li>
                      <b>Insider Opportunities Identification: </b> With your
                      insider's perspective, you're uniquely positioned to
                      identify potential job opportunities, both on our platform
                      and within your personal network. This means you can
                      connect talent to vacancies before they're even listed,
                      giving you a competitive edge in the recruitment process.
                    </li>

                    <li>
                      <b>Candidate Recommendations:</b> Your professional
                      insight enables you to recommend candidates who are not
                      just qualified but are the perfect fit for the role. Our
                      system facilitates your nominations, ensuring that only
                      vetted candidates are put forward, streamlining the
                      employer's selection process.
                    </li>
                    <li>
                      <b>Interviews and Placement:</b> Once you've made your
                      recommendations, employers will conduct interviews. Your
                      role as an Expert is integral in guiding the
                      candidates through to this stage.
                    </li>

                    <li>
                      <b>Employer Shortlisting:</b> Your recommended candidates
                      will be among the employer's shortlisted choices, thanks
                      to your expert endorsements and our vetting process that
                      highlights the best.
                    </li>

                    <li>
                      <b>Reward for Placement:</b> When your recommended
                      candidate is hired, you receive due compensation for your
                      pivotal role in the successful placement, demonstrating
                      the value and impact of your expert involvement.
                    </li>
                  </ul>
                </p>
              </Typography>
            </div>
          </div>
        </section>

        <section className={classes.section}>
          <div className={classes.secitonMainContent}>
            {/* <img src={section2img} alt="Employers" /> */}
            <div className={classes.sectionInfo}>
              <Typography variant="h2" align="left" borderBottom>
                Employers
              </Typography>

              <Typography variant="p">
                At Experts Circle, we connect you with verified and highly rated
                experts across a multitude of fields. Here’s how we simplify
                your hiring process:
              </Typography>

              <EmployerFlow />
              <Typography variant="p">
              <h4>Your Journey as a hiring manager at Experts Circle</h4>
                <ul>
                  <li>
                    <b>Post a Job:</b> Share your vacancy on our exclusive
                    platform for immediate visibility among top-tier experts.
                  </li>
                  <li>
                    <b>Expert Alerts: </b> Our network of industry experts is
                    automatically notified, ready to recommend candidates who
                    match your criteria.
                  </li>
                  <li>
                    <b>Quality Candidates:</b> Review a curated list of
                    pre-vetted professionals, each endorsed by experts with
                    deep industry knowledge.
                  </li>
                  <li>
                    <b>Interview & Hire:</b> Interview top candidates and make
                    your selection, confident in the quality assured by our
                    expert-driven process.
                  </li>
                  <li>
                    <b>Reward Excellence:</b> Finalize the hire and our platform
                    rewards the experts responsible for the match,
                    ensuring a cycle of quality and satisfaction.
                  </li>
                </ul>
              </Typography>

              <Typography variant="p">
                <b>Value-Driven Partnership: </b>
                While Experts Circle retains a modest portion of the recruitment
                fee, the majority is awarded to the experts responsible for the successful placement, ensuring that our
                interests are aligned with delivering the best possible matches
                for your needs.
              </Typography>
            </div>
          </div>
        </section>

        <section className={classes.section}>
          <div className={classes.secitonMainContent}>
            <img src={section3img} alt="Candidates" />
            <div className={classes.sectionInfo}>
              <Typography variant="h2" align="left" borderBottom>
                Candidates
              </Typography>

              <Typography variant="p">
              <h4>Your Journey as a Candidate at Experts Circle</h4>                
                <ul>
                  <li>
                    <b>Endorsed by Experts: </b> At Experts Circle, candidates
                    are not just applicants—they are professionals who have been
                    rigorously recommended by our network of experts.
                    This endorsement means you’re not just another name in the
                    pile; you're a top-tier candidate put forward by someone who
                    truly understands what it takes to excel in your field.
                  </li>
                  <li>
                    <b>Thorough Verification: </b> Before any recommendation is
                    finalized, your credentials and fit for each role are
                    carefully verified and screened. This includes checks by our
                    advanced system and a review by an independent expert. This
                    dual-layer verification ensures that your expertise aligns
                    perfectly with the job's requirements, positioning you as a
                    prime candidate.
                  </li>
                  <li>
                    <b>Pathway to Become an Expert: </b> As a
                    candidate within Experts Circle, you have the unique
                    opportunity to transition into a role as an Expert, 
                    provided you meet the specific criteria. This
                    potential career advancement allows you to leverage your
                    industry knowledge and network to influence hiring in your
                    field positively.
                  </li>
                </ul>
              </Typography>
            </div>
          </div>
        </section>

        <section className={classes.section}>
          <div  className={classes.secitonMainContent}>
            {/* <img src={section1img} alt="Experts" /> */}
{/*             <div className={classes.sectionInfo}>
              <Typography variant="h2" align="left" borderBottom>
                What's Next at Experts Circle
              </Typography>

              <Typography variant="p">
                To further enhance our expert-driven recruitment approach, we're
                introducing a notification system for Experts. This
                will alert them to new vacancies perfect for their insider
                knowledge, making the task of finding and verifying the best
                candidates even more seamless.
              </Typography>

              <Typography variant="p">
                Moreover, we're also conceptualizing a revolutionary system to
                directly connect experts with those actively seeking
                employment. This initiative, aimed at further simplifying the
                recruitment process for our experts, will ensure the
                right talent is matched with the right roles effortlessly. These
                initiatives are part of our ongoing efforts to optimize
                recruitment, ensuring that every match made is a step towards a
                more efficient and effective hiring process for all.
              </Typography>
            </div> */}
          </div>
        </section>
        {/* <section className={classes.section}>
          <HowItWorkSection/>
        </section>  */}
      </div>
    </Layout>
  );
}
